import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class BannerSection extends React.Component {

  render() {

    return (
      <section className="banner alngpad wallet-banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side">
                <h1 className="main-heading"><span className='bluecolor'>Crypto Token</span> Development Company</h1>
                <p className="pharagraph">Adopt our Crypto token development services to create tokens of the highest order on popular blockchains. We create all kinds of tokens for a number of purposes based on your preferences. We create highly secure, efficient, and unmatchable crypto-based products.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Create crypto token with us</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
            <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/crypto-token/crypto-token-development.png"
                alt="Crypto Token Development Services"
                className='d-none d-md-block'
                width={743}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection