import React from 'react'


const Popular = () => {


  return (
    <section className="pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h4 className="heading-h2">Crypto Token Creation Services For Popular <span className="bluecolor">CrowdFunding Techniques</span> 
            </h4>
            <p className="pharagraph head">We create Crypto tokens that are entirely used for crowdfunding purposes and for making the process easier.
            </p>
          </div>
        </div>
        <div className='row popular'>
            <div className='col-md-12 col-lg-3'>
                <img className='lazyload on' width="60px" height="60px" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/ico.png" alt="ICO Development" />
                <img className='lazyload off' width="60px" height="60px" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/ico-1.png" alt="ICO Development" />
                <h3>ICO Development</h3>
                <p className='pharagraph'>ICO or Initial Coin Offering is the crowdfunding technique used by Crypto startups to raise funds for their business by creating a website. Our tokens can be listed on the ICO website for investors to reach and buy.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <img className='lazyload on' width="60px" height="60px" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/ieo.png" alt="IEO Development" />
                <img className='lazyload off' width="60px" height="60px" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/ieo-1.png" alt="IEO Development" />
                <h3>IEO Development</h3>
                <p className='pharagraph'>IEO or Initial Exchange offering is the Crowdfunding technique where the startups approach a popular exchange to list them. Our standardized token creation is quite useful during the IEO Launch.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <img className='lazyload on' width="60px" height="60px" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/sto-1.png" alt="STO Development" />
                <img className='lazyload off' width="60px" height="60px" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/sto.png" alt="STO Development" />
                <h3>STO Development</h3>
                <p className='pharagraph'>We create specific security tokens that play a requisite part during the Security token offering launch process. We build according to SEC regulations and guidelines.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <img className='lazyload on' width="60px" height="60px" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/ido.png" alt="IDO Development" />
                <img className='lazyload off' width="60px" height="60px" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/ido-1.png" alt="IDO Development" />
                <h3>IDO Development</h3>
                <p className='pharagraph'>IDO or initial DEX Offering is the process where startups raise capital by creating and selling them on popular DEX platforms. We are specialized in creating IDO-based tokens for our clients. </p>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Popular