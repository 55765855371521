import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h4 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Process</span> of our Crypto Token Development</span></h4>
            <p className="pharagraph head">Coinsclone’s crypto token developer team follow some standard procedures to launch digital assets with high quality.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
        <div className="col-lg-6 col-md-12  text-center order1" >
                  <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/paxful-new/requirment-and-planning.png" 
                      alt="Development and Approach"
                      width={310}
                    />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Requirements gathering</h5>
            <p className="pharagraph" >Requirements Gathering is the basic step in our creation process where we find and understand the purpose. By taking this route, we gather the priorities of the client including the blockchain required for creation.
            </p>
            <br />
            <h5 >Planning </h5>
            <p className="pharagraph" >In the planning phase, we prepare an outline of the entire process from defining the scope and fixing the timelines. We also check for compliance guidelines and build a framework to carry out the token development process systematically.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5 >Designing</h5>
            <p className="pharagraph" >Design is a key parameter in the development of virtual assets. We ensure the design is up to the mark and is embarked with top-notch creativity. Also, providing the specifications for the token standards comes under the design part.</p>
            <br />
            <h5 >Development</h5>
            <p  className="pharagraph">We develop tokens on diverse standards like ERC20, and TRC20 according to the requirements. The newly developed tokens will be ready to enter the market and to be deployed into the ideal platform.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
               <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/paxful-new/design-development.png" 
                      alt="Designing and development"
                      width={310}
                    />
          </div>
        </div>
        <div className="row table-content orderflex mb-0 order1">
        <div className="col-lg-6 col-md-12  text-center order1" >
              <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/paxful-new/testing-and-deployment.png" 
                      alt="Testing and deployment"
                      width={295}
                    />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2 mb-0">
            <h5>Deployment</h5>
            <p className="pharagraph">We develop tokens that fulfill the needs to be deployed into a popular exchange or a marketplace platform according to the Client’s necessity. Post-deployment, the token will be available on popular listing sites where the prices can be tracked.
            </p>
            <br />
            <h5>Beta testing</h5>
            <p className="pharagraph mb-0">Beta testing is the final round of the development process where it is checked for bugs or errors. Then, it is released to a set of audiences in the real world before it gets listed on an exchange or a listing site.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach