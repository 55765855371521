import React from 'react'



class TypesOf extends React.Component {


  render() {

    return (
      <section className="icon whitelabel-innove pt-100 typeof">
        <div className="container">
          <h2 className="main-heading text-center"><span className='bluecolor'>Types of</span> Crypto Tokens We Create</h2>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                <a href='https://www.coinsclone.com/utility-token-development/'>
                Utility Token Development
                </a>
                </h3>
                <p className="pharagraph">Utility tokens can add a lot of value to your Crypto project as their main purpose of development is for crypto crowdfunding. You can create utility tokens, adopt Crypto crowdfunding techniques like ICO(Initial Coin Offering), and make profits largely.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                  <a href='https://www.coinsclone.com/sto-development-company/'> 
                Security Token Creation
                </a>
                </h3>
                <p className="pharagraph">Security tokens are more or less similar to utility assets but are used for the purpose of another crowdfunding technique like STO. Create security tokens backed by real-world assets, ensure packed security, and align with SEC guidelines.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                  <a href='https://www.coinsclone.com/defi-token-development/'>
                DeFi Token Development
                </a>
                </h3>
                <p className="pharagraph">Create a Defi token that serves as the base for completely decentralized platforms. Users can access diverse functionalities in the Defi protocol with the help of tokens we create for you. Creating a Defi token is a smart strategy used by many startups to make money.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                  <a href='https://www.coinsclone.com/nft-token-development/'>
                NFT Token Development
                </a>
                </h3>
                <p className="pharagraph">Non-fungible tokens are unique digital assets where ownership is stored on a blockchain for enhanced security. The demand for NFTs is massive at the moment and by creating NFTs with us, you have plenty of use-cases.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                  <a href='https://www.coinsclone.com/metaverse-token-development/'>
                Metaverse Token Development
                </a>
                </h3>
                <p className="pharagraph">Metaverse is an innovative concept that has gained a lot of attention in recent years. Metaverse platforms can be accessed by users if they have adequate cryptocurrencies. Build your Metaverse token now which could be the first step to starting your own Metaverse platform.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                  <a href='https://www.coinsclone.com/brc20-token-development/'>
                BRC Token Development
                </a>
                </h3>
                <p className="pharagraph">Our latest services extend to BRC20 tokens as well. An experimental token standard built on the Bitcoin blockchain, allows you to create fungible types of digital assets that run on Ordinal inscriptions.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                  <a href='https://www.coinsclone.com/asset-backed-crypto-token-development/'>
                Asset Token Development
                </a>
                </h3>
                <p className="pharagraph mb-0">Asset tokens are digital assets created to represent ownership of assets, enhances liquidity, enables fractional ownership, ensures security, and attracts global investors through blockchain technology.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                  <a href='https://www.coinsclone.com/stablecoin-development-service/'>
                Stable coin Development
                </a>
                </h3>
                <p className="pharagraph mb-0">The purpose of a stablecoin is to provide price stability, enabling secure, cross-border transactions and investments in the crypto market. We create stablecoins that are pegged to certain assets, such as fiat currency, etc</p>
              </div>
              </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TypesOf