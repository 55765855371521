import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react'


class WhyChoose extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tab-items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h4 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Why Coinsclone</span> Is The Best</span>
            Crypto Token Development Company?</h4>
            <p>Coinsclone is the best crypto token development company that specializes in creating and deploying Crypto tokens for blockchain-based projects, providing end-to-end solutions for tokenization needs.</p>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab1" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab_1')}>
                  On-Time Delivery
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab_2')} >
                  Expert Team
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab_3')} >
                  24/7 Technical Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab_4')} >
                  Greater security
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab_5')} >
                  Periodic Updates
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab_6')} >
                  Quality Assessment
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab_1" style={{display: "block"}} className="tab-pane tab-items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">On-Time Delivery
                        </p>
                        <p className="pharagraph">We gather complete token requirements from our Clients and we see to it that the Crypto token is delivered in time without much delay.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage 
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-token/on-time-product-delivery.png"
                      alt="On-time product delivery"
                      width={245}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab_2" className="tab-pane tab-items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">Expert Team</p>
                        <p className="pharagraph">Our panel of Blockchain tokenization development experts has the experience and capability to produce an impeccable token according to the needs. We handle your Crypto token development project with utmost care.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage 
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-token/expert-team.png"
                      alt="Expert team"
                      width={253}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab_3" className="tab-pane tab-items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">24/7 Technical Support</p>
                        <p className="pharagraph">Our Crypto token development company doesn’t just stop with development and our objective is to provide 24/7 technical support to you. As a leading Crypto token development company, our support system will work towards fixing and resolving any issues in minutes
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage 
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-token/technical-support.png"
                      alt="24/7 technical support"
                      width={256}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab_4" className="tab-pane tab-items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">Greater Security
                        </p>
                        <p className="pharagraph">We understand the value and importance of a secured token development which makes us the right choice for you. Apart from the mandatory security we provide, you can add additional security at the lowest cost with us.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage 
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-token/greater-security.png"
                      alt="Greater security"
                      width={256}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab_5" className="tab-pane tab-items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">Periodic Updates</p>
                        <p className="pharagraph">As a top Crypto token development company, we believe in Clients having periodic updates from time to time about the project. We keep updating the project’s stages adequately and honestly.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage 
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-token/periodic-updates.png"
                      alt="Periodic updates"
                      width={308}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab_6" className="tab-pane tab-items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">Quality Assessment</p>
                        <p className="pharagraph">By adopting our Crypto token development services, clients can be confident that the quality of the token will not be compromised at any instance. We conduct quality assessment tests before delivering the final Crypto token to you.</p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage 
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-token/quality-assessment.png"
                      alt="Quality assessment"
                      width={267}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default WhyChoose