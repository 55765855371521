import React from "react"



class DevService extends React.Component {

  render() {
    return (
      <div className="pt-100">
      <section className="usr-admin-tab benifor cryptoken mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Crypto Token <span className="bluecolor">Development Services</span> Provided By Our Company</h2>
            <p className="text-center">
            Our token creation helps you build custom tokens for your project, ensuring a smooth launch and functionality on your chosen blockchain.
            </p>
            <div className="row">
                  <div className="d-lg-flex flex-wrap add-one">
                    <div className="square" >
                      <h3>Create and Integrate Token</h3>
                      <p className="pharagraph">
                      Create your crypto token and integrate it with the existing business system to boost utility and adoption that attract global investors from around the world instantly. We provide bug-free token creation services on any blockchain to meet several business use cases.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Blockchain Integration</h3>
                      <p className="pharagraph">
                      Our blockchain integration services allow business improvisations by incorporating an immutable ledger where all the transactions are stored securely. We ensure seamless integration services into any one of the existing applications.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Digital Wallet</h3>
                      <p className="pharagraph">
                      We develop digital wallets to store and access digital currencies throughout the digital world. Our wallets are highly scalable and compatible and come with the best security features in the industry including two-factor authentication, etc.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Token Transfer</h3>
                      <p className="pharagraph">
                      The tokens we develop are enabled with the option to be cross-transferred from one blockchain to another for specific purposes. The transfer feature is essential for blockchain ecosystem functioning and also for the easy transferability of digital assets.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Token Migration</h3>
                      <p className="pharagraph">
                      Our migration services allow you to transfer the old ones to upgraded ones due to a changeover in the blockchain project. Also, we facilitate the option of moving the tokens completely from one blockchain to another for a predetermined conversion rate.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Token Minting and Burning</h3>
                      <p className="pharagraph">
                      We provide minting and burning services that permit new tokens to be created on the blockchain and permanently remove the unnecessary ones from circulation. This is usually done to increase the demand and value for the others in circulation.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Listing on Exchange</h3>
                      <p className="pharagraph">
                      Our extensive development includes listing on exchange as well. As a top-notch token service provider, we research and find the best-suited exchange to list.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Whitepaper Writing Services</h3>
                      <p className="pharagraph">
                      We write whitepapers after deep research to help owners market across the investors. Our whitepaper services offer knowledge and expertise about the token from top to bottom with proven facts.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Smart Contract Development</h3>
                      <p className="pharagraph">
                      Our experts design, develop, and deploy highly valuable smart contracts in solidity language for business requirements. Additionally, we provide end-to-end smart contract services that facilitate automation, transparency, and decentralization.
                      </p>
                    </div>
                  </div>
            </div>
          </div>
          </div>
      </section>
      </div>
    )
  }
}

export default DevService
